@font-face {
  font-family: DINAlternateBold;
  src: url("./Fonts/DINAlternateBold.otf");
}

@font-face {
  font-family: DINAlternateRegular;
  src: url("./Fonts/DINAlternateRegular.otf");
}

#App {
  font-family: DINAlternateRegular, sans-serif;
  display: flex;
  flex-direction: column;
}

#AppM {
  font-family: DINAlternateRegular, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%
}

body {
  background-color: #eceeed;
}

a {
  color: #073d3c;
  text-decoration: none;
}

h1 {
  color: #074044;
}

h2 {
  color: #3f8e93;
  margin: 0;
  margin-left: 3rem;
  font-size: 3rem;
}

h3 {
  color: #06585d;
  font-size: 2rem;
}

p {
  color: #043c40;
  font-size: 1.2rem;
}

label,
input {
  margin: 0.5rem;
  margin-bottom: 1.3rem;
  color: #043c40;
}

label {
  font-weight: bold;
}

input {
  background-color: #e6eeed;
  width: 30vh
}

#h1M {
  display: flex;
  flex-direction: column;
  justify-content: start;
  color: #074044;
  font-size: 4.5rem;
}

#arrow{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  width: 5%;
}

#SEJLogo {
  width: 30%;
}

#headerPhoto {
  width: 100%;
}

#SEJ {
  position: absolute;
  opacity: 0.4;
  height: 60%;
}

#headerText {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 3rem;
}

.multiItemMove {
  display: flex;
  flex-direction: row;
  width: 100vw;
  background-color: #eceeed;
  /* justify-content: space-between; */
  align-items: space-around;
}

.multiItemMoveT {
  display: flex;
  flex-direction: row;
  width: 80vw;
  background-color: #eceeed;
  justify-content: space-around;
  /* align-items: space-around; */
}

.mobileBox {
  display: flex;
  flex-direction: column;
  width: 95%;
  background-color: #eceeed;
  justify-content: space-around;
  align-items: center;
}

.dialogBoxD {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 5.2rem;
  /* margin-right: 3rem; */
}

.dialogBox {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-left: 3rem;
  /* margin-right: 3rem; */
}

.dialogBoxT {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.dialogBoxM {
  padding: 1rem;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 95%;
}

#dialogBoxPhoto {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.listBreak {
  padding-bottom: 0.5rem;
}

.listBreakM {
  padding-bottom: 0.8rem;
}

#about-header{
  padding:0;
  margin:0;
  font-size: 2rem;
}

#list {
  padding-left: 0.1rem;
  padding-bottom: 0.2rem;
}

#education {
  padding-top: 0rem;
  padding-bottom: 0rem;
  padding-left: 2rem;
}

#leftAlignText {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

#navagateButton {
  display: flex;
  background-color: #84afae;
  height: 3rem;
  width: 30vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  margin-left: 1rem;
  font-size: 1.3rem;
}

#navagateButton:hover {
  display: flex;
  background-color: #6A8C8E;
  height: 3rem;
  width: 30vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  margin-left: 1rem;
  font-size: 1.3rem;
}

#navagateButtonM {
  display: flex;
  background-color: #84afae;
  height: 3rem;
  width: 50vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  margin-left: 1rem;
  font-size: 1.3rem;
}

#navagateButtonM:hover {
  background-color: #6A8C8E;
}

#headShot {
  width:80%;
  height: auto;
  margin-right: 4rem;
  border-color: white;
  border-width: medium;
  border-style: solid;
  background-color: #06585d;
  padding: 2rem;
}

#headShotT {
  width: 60%;
  margin: 1rem;
  border-color: white;
  border-width: medium;
  border-style: solid;
  background-color: #06585d;
  padding: 2rem;
  margin-left: 2rem
}

#headShotM {
  width: 70%;
  height: 70%;
  margin: 1rem;
  border-color: white;
  border-width: medium;
  border-style: solid;
  background-color: #06585d;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#benniShot {
  width: 60%;
  margin: 1rem;
  margin-left: 3rem;
  border-color: white;
  border-width: medium;
  border-style: solid;
  background-color: #06585d;
  padding: 2rem;
}

#biffFamT {
  width: 30%;
  margin: 1rem;
  margin-left: 3rem;
  border-color: white;
  border-width: medium;
  border-style: solid;
  background-color: #06585d;
  padding: 2rem;
  margin-left: 18rem
}

#biffFamM {
  width: 70%;
  margin: 0.5rem;
  border-color: white;
  border-width: medium;
  border-style: solid;
  background-color: #06585d;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#biffFam {
  width: 90%;
  margin: 1rem;
  margin-left: 3rem;
  border-color: white;
  border-width: medium;
  border-style: solid;
  background-color: #06585d;
  padding: 2rem;
}

#biffFamT {
  width: 30%;
  margin: 1rem;
  margin-left: 3rem;
  border-color: white;
  border-width: medium;
  border-style: solid;
  background-color: #06585d;
  padding: 2rem;
  margin-left: 18rem
}

#biffFamM {
  width: 70%;
  margin: 1rem;
  border-color: white;
  border-width: medium;
  border-style: solid;
  background-color: #06585d;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator {
  width: 100%;
  margin-bottom: -20rem;
}

.separatorM {
  width: 100%;
}

#arrowSeparator {
  width: 10%;
}

.content {
  width: 100%;
  z-index: 1;
}

#projectTile1 {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 30%;
}

#projectTile2 {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 40%;
}

#projectTile3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 80%;
}

#projectTitle {
  font-size: 1.5rem;
  text-align: center;
}

#projectIcon {
  height: 100%;
}

#projectIconM {
  width: 70%;
}

#workDescription {
  color: #073d3c;
  font-size: 1rem;
}

#workDetails {
  text-align: center;
}

.projects {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Plx--below {
  display: flex;
  justify-content: center;
}

.Plx {
  display: flex;
  justify-content: center;
}

#mProjects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#contactForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  padding: 3rem;
  margin-left: 10rem;
  margin-right: 10rem;
  background-color: #fbfdfd;
}

#contactFormM {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 1.2rem;
  padding: 2rem;
  background-color: #fbfdfd;
  width: 80%
}

#contactHeader {
  display: flex;
}

#contactHeaderM {
  font-size: 2.5rem;
  padding-left: 0;
  padding-right: 0
}


#basicContact {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

#basicContactM {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

#message {
  width: 100%;
}

#messageBox {
  width: 100%;
  height: 7rem;
}

#messageBoxM {
  width: 95%;
  height: 7rem;
}

#loader {
  display: flex;
  justify-content: center;
  align-content: center;
}

#sumbit {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

#submitButton {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #043c40;
  border: 2px solid #c1cecd;
  border-radius: 5px;
  color: white;
  font-size: 1.4rem;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  margin: 0.5em;
  width: 20vw;
  height: auto;
  font-family: SoftLine, sans-serif;
}

#submitButtonM {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #043c40;
  border: 2px solid #c1cecd;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  margin: 0.5em;
  width: 20vw;
  height: auto;
  font-family: SoftLine, sans-serif;
}

#footer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: nowrap;
  z-index: 1;
  background-color: rgba(255,255,255, 0.5);
}

#footerM {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: nowrap;
}

#social {
  margin-left: 10rem;
  display: flex;
  flex-wrap: nowrap;
}

#socialM {
  display: flex;
  flex-wrap: nowrap;
}

#copy {
  padding-right: 1rem;
  margin-left: 20rem;
}

#copyM {
  padding-right: 1rem;
}

.socialPhoto {
  width: 40px;
  padding: 1rem;
  padding-bottom: 0
}

.socialPhotoT {
  width: 30px;
  padding-right: 0.5rem;
}

.socialPhotoM {
  width: 25px;
  padding-right: 0.5rem;
}

#mobileCopy {
  font-size: 0.6rem
}
